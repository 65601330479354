<script setup lang="ts">
import { computed, resolveDynamicComponent } from 'vue';
import { RouterView } from 'vue-router';
import { useStyle } from '@/styleProvider';

useStyle('root');

const modulesRootWrapperExists = computed<boolean>(
  () => typeof resolveDynamicComponent('RootWrapper') !== 'string',
);
</script>

<template>
  <component v-if="modulesRootWrapperExists" :is="'RootWrapper'">
    <RouterView />
  </component>
  <RouterView v-else />
</template>
