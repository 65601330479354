import { createApp, defineAsyncComponent } from 'vue';
import { createPinia } from 'pinia';
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
import { createI18n } from 'vue-i18n';
import VueAxios from 'vue-axios';

import _ from 'lodash';
import axios from 'axios';

import RootView from '@/RootView.vue';
import { StyleProvider } from '@/styleProvider';
import { AXIOS, STYLE } from '@/const';

import type { IModuleContext } from './types';

const app = createApp(RootView).use(VueAxios, axios).use(createPinia());

const moduleImports = import.meta.glob('~/**/module.init.ts');

const moduleInits = _.map(moduleImports, (m) => m());

Promise.all(moduleInits).then((modules) => {
  const initContext: IModuleContext = {
    routes: {},
    components: {},
    messages: {},
    styles: {},
    app,
  };

  const context = _.chain(modules)
    .sortBy((m: any) => m.priority ?? m.PRIORITY ?? 0)
    .reduce<IModuleContext>((ctx: IModuleContext, module: any) => module.init(ctx), initContext)
    .value();

  const routeTransform = (
    acc: RouteRecordRaw[],
    route: Record<string, any>,
    name: string,
  ): RouteRecordRaw[] => {
    route.children = _.chain(route.children).reduce<RouteRecordRaw[]>(routeTransform, []).value();
    return [...acc, { ...route, name } as RouteRecordRaw];
  };

  const routes = _.chain(context.routes).reduce<RouteRecordRaw[]>(routeTransform, []).value();

  _.each(context.components, (cmp, name) =>
    app.component(name, _.isFunction(cmp) ? defineAsyncComponent(cmp) : cmp),
  );

  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: routes,
  });

  const i18n = createI18n({
    legacy: false,
    locale: import.meta.env.VITE_DEFAULT_LOCALE,
    fallbackLocale: 'ru',
    messages: context.messages,
  });

  app.use(router).use(i18n);

  const styleProvider = new StyleProvider();
  styleProvider.appendStyles(context.styles);

  app.provide(AXIOS, axios);
  app.provide(STYLE, styleProvider);

  app.mount('#app');
});
